import { Button, Icon, Modal, Snack, Snackbar, Text } from '@nike/eds';
import './definition-solve-result-page.css';
import { useState } from 'react';

export interface SolveValueModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSave?: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
}

// TODO: pull this form out of a modal so its easy to test without overriding tappable
// our usage of the modal is also just on a single boolean, so you could argue theres no need to test it
export function SolveValueModal({ visible, setVisible, setSave, value }: SolveValueModalProps) {
  const [isClicked, setIsClicked] = useState(false);
  const handleCopy = async () => {
    await navigator.clipboard.writeText(document.querySelector('.value-cell')?.textContent || '');
    setIsClicked(true);
  };
  return (
    <Modal
      isOpen={visible}
      onDismiss={() => setVisible(false)}
      headerSlot={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text font="title-3" as="h3">
            Value
          </Text>
          <div>
            <Button
              variant="secondary"
              afterSlot={<Icon name="CopyPaste" size="m" />}
              style={{ padding: '5px', marginRight: '10px' }}
              onClick={handleCopy}
            >
              Copy
            </Button>
            <Snackbar>
              {isClicked && (
                <Snack
                  id="1"
                  onDismiss={id => setIsClicked(false)}
                  autoDismissDuration={2000}
                  status="success"
                >
                  <p className="eds-type--body-2">Copied to Clipboard!</p>
                </Snack>
              )}
            </Snackbar>
          </div>
        </div>
      }
      // footerSlot={
      //   setSave && (
      //     <Button
      //       onClick={() => {
      //         setVisible(false);
      //         setSave(true);
      //       }}
      //     >
      //       Save File
      //     </Button>
      //   )
      // }
    >
      <Text className="value-cell">{value}</Text>
    </Modal>
  );
}

export default SolveValueModal;

/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unknown-property */
import { Object3D, MathUtils } from 'three';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Rhino3dmLoader, OBJLoader, PCDLoader } from 'three-stdlib';
import * as THREE from 'three';

import { PropsWithChildren, useEffect, useState } from 'react';

import { TumbleControls } from './tumble-controls';

export function OBJModel({ data }: { data: string }) {
  function convertData() {
    const loader = new OBJLoader();
    const threedobject = loader.parse(data);
    return threedobject;
  }

  return <primitive object={convertData()} />;
}

export function Rhino3dmModel({
  data,
  material,
  position,
}: {
  data: string;
  material: THREE.Material;
  position?: THREE.Vector3;
}) {
  const [renderState, setRenderState] = useState<Object3D>();

  async function convertData() {
    let obj = null;
    try {
      obj = JSON.parse(data);
    } catch (e) {
      // string but not JSON
    }

    if (!obj || !obj.opennurbs) {
      return;
    }

    const rhino = await (window as any).rhino3dm();
    const doc = new rhino.File3dm();
    const mesh = rhino.CommonObject.decode(obj);
    doc.objects().add(mesh, null);

    const { buffer } = new Uint8Array(doc.toByteArray());
    const loader = new Rhino3dmLoader();
    loader.setLibraryPath('https://cdn.jsdelivr.net/npm/rhino3dm@8.0.0-beta/');

    loader.parse(buffer, o => {
      //
      // GH puts the z-axis, rotate to show the model the same in ThreeJS
      //
      o.rotateX(MathUtils.degToRad(-90));

      o.traverse(c => {
        if (c instanceof THREE.Mesh) {
          // eslint-disable-next-line no-param-reassign
          c.material = material;
        }
      });

      setRenderState(o);
    });
  }

  useEffect(() => {
    convertData();
  }, [data]);

  const pos = position || new THREE.Vector3(0, 0, 0);

  return (
    renderState && <primitive object={renderState} position={pos} children-0-material={material} />
  );
}

export function Cube() {
  return (
    <mesh>
      <boxGeometry args={[2, 2, 2]} />
    </mesh>
  );
}

export function Sphere() {
  return (
    <mesh position={[-4, 0, 0]}>
      <sphereGeometry args={[1]} />
    </mesh>
  );
}

export interface PointCloudMetadata {
  data: string;
  color: THREE.ColorRepresentation;
  size?: number;
}

function PointCloud({ metadata }: { metadata: PointCloudMetadata }) {
  const [renderState, setRenderState] = useState<THREE.Object3D>();

  const convertData = () => {
    const loader = new PCDLoader();
    const arrayBuffer = new TextEncoder().encode(metadata.data).buffer;
    const points = loader.parse(arrayBuffer, '');

    const material = new THREE.PointsMaterial({ size: metadata.size, color: metadata.color });
    points.material = material;

    setRenderState(points);
  };

  useEffect(() => {
    convertData();
  }, [metadata]);

  return renderState && <primitive object={renderState} position={new THREE.Vector3(0, 0, 0)} />;
}

export function Visualizer({ children }: PropsWithChildren) {
  return (
    <Canvas style={{ background: '#FFFFFF', height: '768px', width: '1024px' }}>
      <PerspectiveCamera makeDefault position={[0, 5, 10]} />
      <OrbitControls />
      <ambientLight intensity={0.4} />
      <pointLight position={[10, 10, 10]} decay={0} intensity={1} />
      {children}
    </Canvas>
  );
}

// Adobe Illustrator on MacOS does not properly support PointerEvents, so we can't use OrbitCamera
type AdobeVisualizer = {
  position: [number, number, number];
  view?: string;
};

export function AdobeVisualizer({ children, position, view }: PropsWithChildren<AdobeVisualizer>) {
  const zoom = Math.sqrt(
    position[0] * position[0] + position[1] * position[1] + position[2] * position[2]
  );

  return (
    <Canvas style={{ background: '#FFFFFF' }}>
      <PerspectiveCamera makeDefault />
      <ambientLight intensity={0.4} />
      <hemisphereLight position={[1, 1, 1]} color={0xffffff} groundColor={0} intensity={9} />
      <TumbleControls view={view} zoom={zoom}>
        {children}
      </TumbleControls>
    </Canvas>
  );
}

interface RibbonVisualizerProps {
  ribbon: THREE.Mesh;
  uuid: number;
}

export function RibbonVisualizer({ ribbon, uuid }: RibbonVisualizerProps) {
  return <primitive key={`ribbon-${uuid}`} object={ribbon} position={[10, 5, 125]} />;
}

type PCDVisualizer = {
  data: string;
};

export function PCDVisualizer({ metadata }: { metadata: PointCloudMetadata[] }) {
  return (
    <Canvas style={{ background: '#FFFFFF', height: '768px', width: '1024px' }}>
      <PerspectiveCamera makeDefault position={[0, 5, 10]} />
      <ambientLight intensity={0.4} />
      <pointLight position={[10, 10, 10]} decay={0} intensity={1} />
      <TumbleControls view="default" zoom={0}>
        {metadata.map(data => (
          <PointCloud metadata={data} />
        ))}
      </TumbleControls>
    </Canvas>
  );
}
